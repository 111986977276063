import { getDataByEnv } from '@/core/utils'

const customDataByEnv = {
  namespace: {
    production: 'f24aeea3-1c31-4bac-8321-49c207cc730e',
    development: 'c1339422-a15e-405b-8d19-eebde6a9744e',
  },
}

export default {
  name: {
    nb: 'Betongarbeid',
  },
  slug: 'betongfag',
  namespace: getDataByEnv('namespace', customDataByEnv),
  analytics: {
    gtm: 'GTM-55GZPS6',
  },
  assets: {
    logo: '/themes/betongfag/images/logo.svg',
    favicon: '/themes/betongfag/favicon.ico',
  },
  dbok: {
    nb: [{ link: 'https://reader.dbok.no/#/' }],
    nn: [{ link: 'https://reader.dbok.no/#/' }],
  },
  styles: [
    '/themes/betongfag/css/betongfag.css',
    '/common/icons/icons.css',
    'https://cdn.jsdelivr.net/npm/katex@0.13.2/dist/katex.min.css',
  ],
  welcomeMessage: {
    nb: '<h1>Velkommen til <i>Betongarbeid nettressurs</i></h1><p>Læremiddelet er utgitt med støtte fra Utdanningsdirektoratet.</p>',
    nn: '<h1>Velkommen til <i>Betongarbeid nettressurs</i></h1><p>Læremiddelet er gitt ut med støtte frå Utdanningsdirektoratet.</p>',
  },
  langs: [
    {
      code: 'nb',
      slug: 'betongfag',
      label: 'Bokmål',
    },
  ],
  mainNav: {
    nb: [
      {
        title: 'Begreper',
        to: '/concepts/begreper',
      },
      {
        title: 'Multimedia',
        to: '/media/multimedia',
      },
      {
        title: 'Fagartikler',
        to: '/articles/fagartikler',
      },
      {
        title: 'Yrkesoppgaver',
        to: '/articles/yrkesoppgaver',
      },
      {
        title: 'StreamBIM',
        href: 'https://app.streambim.com/mgw/api/v2/federation/redirecttoexternallogin?provider=FAGBOKFORLAGET',
      },
    ],
    nn: [
      {
        title: 'Fagomgrep',
        to: '/concepts/begreper',
      },
      {
        title: 'Multimedium',
        to: '/media/multimedia',
      },
      {
        title: 'Fagartiklar',
        to: '/articles/fagartikler',
      },
      {
        title: 'Yrkesoppgåver',
        to: '/articles/yrkesoppgaver',
      },
      {
        title: 'StreamBIM',
        href: 'https://app.streambim.com/mgw/api/v2/federation/redirecttoexternallogin?provider=FAGBOKFORLAGET',
      },
    ],
  },
}
